import { EVENTS } from "./AEventService.js";
import { ANodeSessionTracker } from "../classes/session/ANodeSessionTracker.js";
import { ANodeSessionWindow } from "../classes/session/ANodeSessionWindow.js";
export class ANodeSessionService {
    constructor() {
        this.SessionData = {};
        this.trackers = [];
    }
    async autoInit() {
        Events.hardwire('SessionsChanged', _ => {
            for (let session of Sessions)
                this.SessionData[session.NodeName] = session;
            this.trackers.map(tracker => tracker.onDataReceived(this.SessionData));
            Events.tryInvoke(EVENTS.SESSION_UPDATE_DATA, this.SessionData);
        });
        for (let session of Sessions)
            this.SessionData[session.NodeName] = session;
        this.trackers.map(tracker => tracker.onDataReceived(this.SessionData));
        Events.tryInvoke(EVENTS.SESSION_UPDATE_DATA, this.SessionData);
    }
    /**
     * Display other connected devices on the map
     */
    bindSessionsToMap(opt) {
        const tracker = new ANodeSessionTracker(opt);
        tracker.init(this.SessionData);
        this.trackers.push(tracker);
        if (opt.onChange)
            opt.onChange();
        return opt.mapMarkers;
    }
    genDialog() {
        return new ANodeSessionWindow({
            fetchData: () => this.SessionData
        }).show();
    }
}
